import { phpApi, pyApi } from '@/api/request'
import * as model from '../models'
import * as common from '@/modules/common/models/index'

// 课程详情
export function getCourseDetail(id: string): Promise<model.CourseInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/detail/${id}`
  })
}

export function getAnswerDetail(id: string) {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v2/examinations/${id}/answer_card/`
  })
}

// 课程表
export function getChapterList(params: object): Promise<model.ChapterList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/chapter/lists`,
    params
  })
}

// 课时详情
export function getClassCourseDetail(params: object): Promise<model.ClassInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/class/detail/`,
    params
  })
}

// 测验详情
export function getExamDetail (id: any) {
  return phpApi({
    method: 'GET',
    url: `/fairy/api/v2/examinations/${id}/`,
  })
}

// 学员测验情况
// wiki: http://yapi.hogecloud.com/project/11/interface/api/8781
export function getExamChance({id}: any) {
  return phpApi({
    method: 'GET',
    url:`/fairy/api/v2/examinations/${id}/exam_student/`,
  })
}

// 课程列表
export function getCourseList(params: object): Promise<model.ClassInfo> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/lists`,
    params
  })
}

// 课件列表
export function getCourseMaterialsList(course_id: string, page: number = 1, count: number = 999): Promise<model.MaterialsList> {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/${course_id}/materials`,
    params: {
      page,
      count
    }
  })
}

// 免费订阅
export function subscribeCourse(params: object) {
  return phpApi({
    method: 'GET',
    url: `/h5/content/course/subscribe`,
    params
  })
}

// 课时跟踪数据上报
// wiki: http://yapi.hogecloud.com/project/11/interface/api/1688
export function studyReport(params: object): Promise<any> {
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/course/study_report/`,
    data: params
  })
}

// 获取 已解锁列表
// wiki: http://yapi.hogecloud.com/project/11/interface/api/3406
export function getUnlockList(params: any): Promise<any> {
  let userId = params.userId
  let contentId = params.contentId
  let conigSlug = ['course', contentId].join('_')
  return pyApi({
    method: 'GET',
    url: '/fairy/api/v1/access_manage/unlocked_targets/',
    params: {
      user_id: userId,
      config_slug: conigSlug
    }
  })
}

// 解销 某 课时
// wiki: http://yapi.hogecloud.com/project/11/interface/api/3415
export function updateUnlock(params: any): Promise<any> {
  let userId = params.userId
  let contentId = params.contentId
  let classId = params.classId
  let chapterId = params.chapterId
  let conigSlug = ['course', contentId].join('_')
  let targetId = [chapterId, classId].join(':')
  return pyApi({
    method: 'POST',
    url: '/fairy/api/v1/access_manage/complete_target/',
    data: {
      user_id: userId,
      target_id: targetId,
      config_slug: conigSlug
    }
  })
}

// 资料详情
export function getMaterial(params: any) {
  return pyApi({
    method: 'GET',
    url: `/h5/content/course/${params.course_id}/materials/${params.material_id}`,
    data: params
  })
}

// 统计课时浏览
export function getViewCount({id}: any) {
  return pyApi({
    method: 'GET',
    url: `/h5/content/course/class/view/count`,
    params: {
      id
    }
  })
}

// 信息调查
export function checkNeedJoinGroup({contentType, contentId}: any) {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/before_view_tasks/`,
    params: {
      obj_type: contentType,
      obj_id: contentId
    }
  })
}
// 查询内容当前绑定的表单
// wiki: http://yapi.hogecloud.com/project/11/interface/api/2657
export function getBindingForm({content_type, content_id}:any) {
  return pyApi({
    method: 'GET',
    url: `/fairy/api/v1/data_collection_forms/content_current_bind/`,
    params: {
      content_type,
      content_id
    }
  })
}
// 提交表单
// wiki: http://yapi.hogecloud.com/project/11/interface/api/2561
export function submitForm({
  form_id,
  form_data,
  content_id,
  content_type
}:any) {
  // eslint-disable-next-line camelcase
  return pyApi({
    method: 'POST',
    url: `/fairy/api/v1/data_collection_forms/${form_id}/submit/`,
    params: {
      
    },
    data: {
      form_data,
      content_id,
      content_type
    }
  })
}
