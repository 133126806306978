




















import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { ListSell } from '@/modules/common/models'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox
  }
})
export default class ListCourse extends Vue {
  @Prop({ default: '' }) type!: string
  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderCourseList()
  }

  courseStyle: object = {
    'width': '100%',
    'display': 'flex',
    'flex-direction': 'column',
    'align-items': 'center'
  }
  isLoading: boolean = true
  courseList: Array<model.CourseList> = []
  total: number = 0
  page: number = 1
  count: number = 10
  title: string = '课程'
  mounted() {
    window.scrollTo(0, 0)
    this.renderCourseList()
  }

  get isHaveData() {
    return this.courseList.length
  }

  get getMoreTips() {
    return `全部${this.title ? this.title : '课程'} (${this.total})`
  }

  renderCourseList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.title = this.$route.query.title && String(this.$route.query.title)
    this.getCourseList()
  }

  getCourseList() {
    this.isLoading = true
    api.getCourseList({
      page: this.page,
      count: this.count
    }).then(data => {
      this.courseList = data.data
      this.total = data.page.total
      this.isLoading = false
    }).catch(res => {
      this.courseList = []
      this.total = 0
      this.isLoading = false
      console.error('getCourseList = ', res)
    })
  }

  getListSellItem(item: model.CourseList) {
    let listSellItem: ListSell = {
      content_id: item.content_id,
      type: item.type,
      title: item.title,
      brief: item.brief,
      indexpic: item.indexpic,
      update_time: item.update_time ? item.update_time : '',
      highest_price: Number(item.price),
      lowest_price: Number(item.price),
      subscribe_count: item.subscribe,
      join_membercard: item.join_membercard || item.allow_membercard_discount,
      membercard_discount: item.membercard_discount,
      original_price: item.original_price,
      class_count: item.class_count
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'courseList',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        title: `${this.title}`
      }
    })
  }
}
